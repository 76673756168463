<template lang="">
    <div class="b">
        <div class="secitonlist">
                            <div>{{title}}</div>
                            <div><i class="fa fa-edit fa-lg"></i></div>
                        </div>
    <div style="font-size: smaller;font-weight: normal; color: whitesmoke; padding-left:8px;">{{type}}</div>
                      
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "ListCompoment",
    props: ["title", "type", "id"]
}
</script>
<style scoped>
.secitonlist {
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.b {
    background-color: grey;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 8px;
}

.b:hover:not(.active) {
    background-color: rgb(64, 63, 63);

}

.b.active {
    background: rgb(64, 63, 63);
}
</style>