import mlogo from "@/assets/cwh.png";
import { mapState } from "vuex";
import { Dialog } from "vant";
import moment from 'moment';
export default {
  created() {

  },
  data() {
    return {
      asyncStatus_ready: false,
      isLoaded: false,
      logo: mlogo,
      appversion: "1.0.1",
      counter: 0,
      interval: null,
      editorkey: "0r6bumr6t0yic9fjwl3vhjd6ez9d6mk5rrqbqi9r7uncgmxk",

    }
  },
  watch: {
    counter() {
      if (this.counter == 25 && !this.asyncStatus_ready) {
        try {
          clearInterval(this.interval);
          this.handleServerDown();
          this.asyncDatafetchedStatus();
          this.counter = 0;
        } catch (error) {
          console.log(error);
        }
      }
      if (this.counter < 25 && this.asyncStatus_ready) {
        clearInterval(this.interval);
        this.counter = 0;
      }
    },

  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      globalsetting: (state) => state.globalsetting,
      token: (state) => state.token,
      profile: (state) => state.profile,
      products: (state) => state.products,
      domain: (state) => state.domain,
      listemail: (state) => state.listemail,
      domainforward: (state) => state.domainforward,
      sitebuilder: (state) => state.sitebuilder,
      myorders: (state) => state.listorder,
      listdomainforward: (state) => state.dlistforward,
      histories: (state) => state.histories,
      productsearchinfo: (state) => state.productsearchinfo,
      websetting: (state) => state.websetting,
      medias: (state) => state.medias,
      totalpage: (state) => state.totalpage,
      currentpage: (state) => state.currentpage,
      sections: (state) => state.sections,
      breakcrumbs: (state) => state.breakcrumbs

    }),

  },

  methods: {
    asyncDatafetchedStatus() {
      this.asyncStatus_ready = true;
    },
    notasyncDatafetchedStatus() {
      this.asyncStatus_ready = false;
    },
    replaceByDefault(e) {
      e.target.src = this.lazyimage;
    },
    loadImage() {
      this.isLoaded = true;
    },
    replacehtml(str) {
      let regex = /(<([^>]+)>)/gi;
      return str.replace(regex, "");
    },
    handleCloseApp() {
      Dialog.confirm({
        title: "Web Hosting",
        message: this.lang.lbexitmessage,
        cancelButtonText: this.lang.lbcancel,
        confirmButtonText: this.lang.lbconfirm,
      })
        .then(() => {
          this.$bridge.callHandler("closeApp");
        });
    },
    handleInternet() {
      Dialog.alert({
        title: "Web Hosting",
        message: this.lang.lbconnection,
        confirmButtonText: this.lang.lbok,
      }).then(() => {
        // on close
      });

    },
    handleServerDown() {
      Dialog.alert({
        title: "Web Hosting",
        message: this.lang.lbservermentenance,
        confirmButtonText: this.lang.lbok,
      }).then(() => {
        // on close
      });

    },
    dateconvert(dt) {
      return moment(dt).format('MMM DD, YYYY');
    },
    militodate(dt) {
      return moment.unix(dt).format("DD MMM YYYY")
    },
    isValidURL(url) {
      var r = /(http(s)?:\\)?([\w-]+\.)+[\w-]+[.com|.in|.org]+(\[\?%&=]*)?/;
      return r.test(url);
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    countime() {
      this.interval = setInterval(() => {
        this.counter++;
      }, 1000);

    },
    numberspace(num) {
      if (num) {
        let numspaced = num.match(/.{1,3}/g);
        return numspaced.join(' ');
      }
    },
    validPhone(phone) {
      //var re = /^\+?([0-9]{0,3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ] ?([0-9]{3,5})$/;
      var re = /^\+?([0-9]{0,3})\)?([0-9]{9,12})$/;
      return re.test(phone);
    },
    isvalidPrefix(prefix) {
      //start from a to z or from 0 till 9 and from 1 to 20 charactors
      var re = /^[a-z0-9]{1,20}$/;
      return re.test(prefix);
    },
    clearlocalstore() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("websetting");
      localStorage.removeItem("domain");
    },
    validUsername(username) {
      var re = /^[a-z][a-z0-9]{4,15}$/;
      return re.test(username);
    },
    validPassword(password) {
      var re = /^[a-zA-Z0-9!@#$%^&*]{6,12}$/;
      return re.test(password);
    }

  }

}