<template>
  <div class="container-fluid">
    <div class="m">
      <div class="card">
        <img :src="imginternet" style="width:60px;" />
        <div>{{ lang.lbconnection }}</div>
        <button class="btn btn-info btn-sm text-light" @click="closeapp">{{ lang.lbretry }}</button>

      </div>
    </div>
  </div>
</template>

<script>

import noInternet from '@/assets/nointernet.jpeg';
export default {
  name: "CInternetConnection",
  components: {
  },
  data() {
    return {
      imginternet: noInternet,
    }
  },
  methods: {
    closeapp() {
      if (navigator.onLine) {
        if (this.$route.path === "/") {
          this.$router.go(0);
        } else this.$router.go(-1)
      }
    }
  }
};
</script>

<style scoped>
div .card {
  position: relative;
  width: 90%;
  height: 250px;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  display: flex;
  text-align: center;


  box-shadow: rgba(100, 100, 111, 0.2) 5px 7px 29px 5px;

}

.card div {
  padding-bottom: 8px;
}

.m {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>