<template>
    <div v-if="!asyncStatus_ready">
        <Waiting />
    </div>
    <div v-else class="container">
        <div id="about">

            <div style="display:flex; justify-content:center;"><img :src="logo" style="width: 100px;height:100px;" />
            </div>

            <div style="text-align:justify;">{{ lang.id == 2 ? about.about : about.abouten }}</div>
            <hr style="color:gainsboro;" />
            <div style=""><i class="fa-brands fa-telegram"></i>&nbsp; <a :href="about.telegram" target="_blank">{{
                about.telegram.split("https://t.me/")[1]
            }}</a></div>
            <div><i class="fa fa-phone-volume"></i>&nbsp; {{ about.phone }}</div>
            <div><i class="fa fa-at"></i><span style="color:blue;">&nbsp; {{ about.email }} </span></div>
            <div><i class="fa-brands fa-facebook"></i><span style="color:blue;">&nbsp;
                    {{ about.facebook }}</span></div>
            <div style="display:flex; justify-content:flex-start;">
                <div style="padding-right:8px;"><i class="fa fa-location-dot"></i></div>
                <div>
                    {{ lang.id == 2 ? about.location : about.locationen }}</div>
            </div>
        </div>
        <div style="position:fixed;bottom: 5em; right:0em;padding:8px; color: darkgray; font-size: 10px;">
            {{ lang.lbversion + ' ' +
                appversion
            }}</div>

    </div>
</template>

<script>

import Waiting from '@/components/CWaiting.vue';
import { mapState } from 'vuex';
export default {
    name: "ContactUs",
    components: {
        Waiting

    },
    data() {
        return {

        }
    },
    created() {
        if (!this.about) {
            this.$store.dispatch("getabout");
        }
        this.asyncDatafetchedStatus();

    },
    computed: {
        ...mapState({
            about: (state) => state.about
        })
    },
    methods: {

    }
}
</script>

<style scoped>
#about div {
    margin-bottom: 8px;
}
</style>