<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    //this.$store.dispatch("getprofile");
    this.setlocalstoragetostore();

  },
  methods: {
    setlocalstoragetostore() {
      if (localStorage.token && !this.token) {
        this.$store.state.token = localStorage.token;
      }
      try {
        if (localStorage.websetting) {
          this.$store.state.websetting = JSON.parse(localStorage.websetting);
        }
        if (localStorage.user) {
          this.$store.state.profile = JSON.parse(localStorage.user);
        }
        if (localStorage.domain) {
          this.$store.state.domain = JSON.parse(localStorage.domain);
        }
      } catch (error) {
        console.log("not json");
        this.$router.push('/');
      }


    },
  }
};
</script>
<style >
:root {
  --hover: #5c636a;
  --active: green;
}

* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "Khmer OS Battambang" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main {
  padding: 0;
  margin: 0;
}
</style>
