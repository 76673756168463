import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/views/Home.vue";
import message_view from "@/views/MessageView.vue";
import PageNotFound from '@/views/PageNotFound.vue';
import Setting from '@/views/Setting.vue';
import ContactUs from '@/views/ContactUs.vue';
import LeftMenu from '@/layouts/LeftMenu.vue';
import Section from '@/views/Section.vue';
import Media from '@/views/Media.vue';
import WSetting from '@/views/WSetting.vue';
import EditSection from '@/views/EditSection.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: LeftMenu,
    children: [
      {
        name: 'Home',
        path: "/",
        component: Home
      },
      {
        name: 'Section',
        path: "/section",
        component: Section
      },
      {
        name: 'Media',
        path: "/media",
        component: Media
      },
      {
        name: 'WSetting',
        path: "/setting",
        component: WSetting
      },

      {
        path: "/message/:msg",
        name: "MessageView",
        component: message_view,
        props: true
      },
      {
        path: "/profile",
        name: "Setting",
        component: Setting
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound

      },
      {
        path: '/contactus',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/editsection/:id',
        name: 'EditSection',
        component: EditSection,
        props: true
      }
    ]
  }

];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), routes,
  scrollBehavior() {

    return { top: 0, behavior: 'smooth' }
  }
})
export default router