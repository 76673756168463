<template>
    <div>

        <div class="form-group mb-2" v-show="id == 0">
            <label for="sectiontype">Section Type</label>
            <select class="form-control" id="stype" v-model="selectcom">
                <option value="0">Standard</option>
                <option value="1">Jumbo</option>
                <option value="2">SlideShow</option>
                <option value="3">Contact</option>
               
            </select>
        </div>

        <Standard v-if="selectcom == 0" :obj=data />
        <Jumbo v-if="selectcom == 1" :obj=data />
        <Carousel v-if="selectcom == 2" :obj=data />
        <Contact v-if="selectcom == 3" :obj=data />
        <Service v-if="selectcom == 4" :obj=data />

    </div>
</template>
<script>
/* eslint-disable */
import Standard from '@/components/section/Standard.vue';
import Service from '@/components/section/Product.vue';
import Jumbo from '@/components/section/Jumbo.vue';
import Listcom from '@/components/section/Listcom.vue';
import Carousel from '@/components/section/Carousel.vue'
import Contact from '@/components/section/Contact.vue';
export default {
    name: "Section",
    props: ['id'],
    components: {
        Standard,
        Jumbo,
        Service,
        Listcom,
        Carousel,
        Contact,
    },
    data() {
        return {
            selectcom: 0,// 0: standard,1: testimonial,2:services
            type: 'standard',
            sname: '',
            slang: '',
            data: null,
        };
    },
    created() {
        var bread = [{ name: 'Home', path: '/' }, { name: 'Section', path: '/section' }, { name: 'Edit Section', path: '/editsection/' + this.id }];
        this.$store.state.breakcrumbs = bread;
        if (this.id > 0) {
            const data = this.sections.find((obj) => obj.sec_id === this.id);
            this.sname = data.sec_name;
            this.slang = data.sec_language;
            this.type = data.sec_types;
            this.data = data;
            switch (this.type) {
                case "standard":
                    this.selectcom = 0;
                    break;
                case "jumbotron":
                    this.selectcom = 1;
                    break;
                case "carousel":
                    this.selectcom = 2;
                    break;
                case "contact":
                    this.selectcom = 3;
                    break;
                default:
                    this.selectcom = 4;
                    break;
            }
        }
    },
    watch: {

    },
    methods: {
        edit(data, index) {
            this.id = data.sec_id;
            this.index = index;
            this.sname = data.sec_name;
            this.slang = data.sec_language;
            this.type = data.sec_types;
            this.data = data;

        },

    }
}
</script>
<style scoped>
ul li {
    background-color: darkgray;
    margin: 4px;
    padding: 4px;
    font-weight: bold;
    color: white;
    border-radius: 8px;
}

ul li:hover {
    background-color: var(--hover);
    box-shadow: 2px 2px 2px;
    cursor: pointer;
}
</style>