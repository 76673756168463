<template>
    <div>

        <CAlert v-if="result" title="Section" :detail="message" :type="issuccess" />
        <CWaiting v-if="!asyncStatus_ready" />
        <div v-else>
            <div class="form-group mt-1">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" v-model="obj1.title"
                    placeholder="Title">
            </div>
            <div class="form-group mt-1">
                <label for="contactname">Contact Name</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" v-model="obj1.contactname"
                    placeholder="Contact Name">
            </div>
            <div class="form-group mt-1" v-show="id > 0">
                <label for="Order">Section Order</label>
                <input type="number" class="form-control" v-model="order" placeholder="Title">
            </div>
            <div class="row mt-1">
                <label for="pitcure">Picture</label>
                <div v-show="base64"><img :src="base64" width="100" class="img-thumbnail"
                        style="margin: 0px 0px 8px 0px;" />
                </div>
                <div v-show="imgurl && !base64"><img :src="imgurl" width="100" class="img-thumbnail"
                        style="margin: 0px 0px 8px 0px;" />
                </div>
                <input type="file" class="form-control-file" id="picture" @change="ichange" accept="image/*" />
            </div>
            <div class="form-group mt-1">
                <label for="Description">Description</label>
                <textarea class="form-control" row="3" v-model="obj1.caption"></textarea>
            </div>

            <div class="row p-2">
                <button type="button" class="btn-primary btn-lg btn-block" @click="savedata">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import CAlert from '@/components/CAlert.vue';
import CWaiting from "@/components/CWaiting.vue";
export default {
    name: "standardcom",
    props: ["obj"],
    data() {
        return {
            title: "",
            obj1: { title: "", contactname: "", caption: "", filename: "", filesize: 0 },
            issuccess: "",
            result: null,
            id: 0,
            base64: null,
            imgurl: null,
            order: 1,
            message: "",

        };
    },
    created() {
        if (this.obj) {
            this.setData(this.obj);
        }
    },
    mounted() {
        this.asyncDatafetchedStatus();
    },
    watch: {
        obj() {
            this.setData(this.obj);
        }
    },
    components: {
        CAlert,
        CWaiting,

    },
    methods: {
        setData(obj) {
            this.order = obj.sec_order;
            const dt = JSON.parse(obj.sec_data);
            this.obj1.title = dt.title;
            this.obj1.contactname = dt.contactname;
            this.id = obj.sec_id;
            this.obj1.caption = dt.caption;
            if (dt.filename != "") {
                this.imgurl = process.env.VUE_APP_MEDIA_URL + dt.filename
            } else this.imgurl = null;

        },
        async savedata() {
            this.notasyncDatafetchedStatus();
            if (!this.base64 && this.id > 0) {
                const dt = JSON.parse(this.obj.sec_data);
                this.obj1.filename = dt.filename;
                this.obj1.filesize = dt.filesize

            }
            const dt = JSON.stringify(this.obj1
            );
            const postdata = {
                section_id: this.id,
                section_type: 'contact',
                section_data: dt,
                base64: this.base64,
                sorder: this.order,
                sname: "Contact",
                language: 'en'
            }
            var result;
            if (this.id > 0) {
                result = await this.$store.dispatch("Editsection", postdata);
            } else result = await this.$store.dispatch("Addsection", postdata);
            if (result != false && result.sec_id > 0) {
                this.result = result;
                this.issuccess = true;
                this.message = "Success";
            } else {
                this.issuccess = false;
                this.message = "Failed";
            }
            this.asyncDatafetchedStatus();

        },
        ichange(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            const reader = new FileReader();
            reader.onload = (event) => {
                this.base64 = event.target.result;
                this.obj1.filename = img[0]['name'];
                this.obj1.filesize = img[0].size;

            }
            reader.readAsDataURL(img[0]);

        },
    },
}
</script>
<style scoped></style>