<template>
    <div>
        <h3>Media <button class="btn btn-secondary btn-sm ms-2" style="border-radius: 50%;" @click="isshow = !isshow"><i
                    class="fa fa-plus fa-lg"></i></button></h3>
        <div v-show="result">
            <CAlert title="Media Add" detail="blah blah" :type="issuccess" />
        </div>
        <form v-show="isshow">
            <div class="form-group mt-1">
                <label for="title">Title</label>
                <input type="text" class="form-control" placeholder="Title" v-model="title">
            </div>
            <div class="form-group mt-2 mb-2">
                <label for="pitcure">Image</label>
                <div v-show="img"><img :src="img" width="100" class="img-thumbnail" style="margin: 0px 0px 8px 0px;" />
                </div>
                <input type="file" class="form-control-file ps-1" id="img" @change="ichange" accept=".pdf,image/*">
            </div>
            <div class="row mt-2 p-2">
                <button type="button" class="btn btn-primary btn-lg btn-block" @click="asave">Save</button>
            </div>
        </form>
        <div class="container mt-3">
            <h2>Media List</h2>
            <table class="table table-dark">
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody v-for="media, index in medias " :key="media">

                    <tr>
                        <td>{{ media.filename }}</td>
                        <td>{{ media.display_name }}</td>
                        <td>{{ Math.ceil(media.file_size / 1000) }} KB &nbsp;<span class="btn btn-primary btn-sm"
                                @click="edit(media, index)"><i class="fa fa-edit text-light"></i></span>
                        </td>
                    </tr>

                </tbody>
            </table>
            <ul class="pagination">
                <li class="page-item" @click="prev" v-if="totalpage > 3"><a class="page-link">Previous</a></li>
                <div v-for="p in totalpage " :key="p">
                    <li v-bind:class="p == page ? 'page-item active' : 'page-item'" v-if="p >= pagestart && p <= pageend"
                        @click="page = p">
                        <a class="page-link" href="#">{{
                            p }}</a>
                    </li>
                </div>

                <li class="page-item" @click="next" v-if="totalpage > 3"><a class="page-link" href="#">Next</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import CAlert from '@/components/CAlert.vue';
export default {
    name: "Media"
    ,
    components: {
        CAlert
    },
    created() {
        this.getlist();
    },
    data() {
        return {
            isshow: false,
            title: "",
            img: null,
            issuccess: "",
            result: null,
            file: null,
            $base64: null,
            page: 1,
            id: 0,
            index: 0,
            pagestart: 1,
            pageend: 3,
        }
    },
    watch: {
        page() {
            this.getlist();

            if (this.page == 1) {
                this.pagestart = 1;
                this.pageend = 3;
            }
            else if (this.page == this.totalpage) {
                this.pageend = this.totalpage;

            } else {
                this.pagestart = this.page - 1;
                this.pageend = this.page + 1;

            }
            console.log(this.pagestart + ':' + this.page + ':' + this.pageend);


        }
    },
    methods: {
        asave() {
            if (this.id > 0) {
                this.editmedia();
            } else this.addmedia();
        },
        async getlist() {
            await this.$store.dispatch("Getmedias", this.page);
        },
        edit(data, index) {
            this.index = index;
            this.title = data.display_name;
            this.img = process.env.VUE_APP_MEDIA_URL + data.filename;
            this.id = data.file_id;
            this.isshow = true;
        },
        async addmedia() {
            const postdata = {
                name: this.title,
                filename: this.file.name,
                size: this.file.size,
                base64: this.base64
            }
            var result = await this.$store.dispatch("Addmedia", postdata);
            if (result != false && result.file_id > 0) {
                this.result = result;
                this.issuccess = true
            } else this.issuccess = false;
        },
        async editmedia() {
            var fname = "";
            var size = 0;
            if (this.base64) {
                fname = this.file.name;
                size = this.file.size;
            }
            const postdata = {
                id: this.id,
                title: this.title,
                filename: fname,
                size: size,
                base64: this.base64,
                page: this.page
            }
            var result = await this.$store.dispatch("Editmedia", postdata);
            if (result != false && result.file_id > 0) {
                this.result = result;
                this.medias[this.index] = result;
                this.issuccess = true
            } else this.issuccess = false;
        },
        ichange(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            const ftype = img[0].type
            this.file = img[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                if (ftype != "application/pdf") {
                    this.img = event.target.result;
                }
                this.base64 = event.target.result;

            }
            reader.readAsDataURL(img[0]);



        },
        prev() {
            if (this.page > 1) {
                this.page = this.page - 1
            }


        },
        next() {
            if (this.page < this.totalpage) {
                this.page = this.page + 1
            }

        }
    }
}
</script>
<style scoped></style>