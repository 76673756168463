<template>
  <div class="waiting">
    <div class="spinner-border text-success" style="width: 100px; height: 100px;"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.waiting {
  position: fixed;
  z-index: 20;
  left: 15%;
  overflow: auto;
  width: 70%;
  height: 50%;
  border-radius: 8px;
  background-color: rgba(13, 13, 13, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

}
</style>