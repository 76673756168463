<template>
    <div>
        <form class="form">
            <div class="form-group">
                <label for="title">Name</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Product/Service Name">
            </div>
            <div class="form-group mt-2 mb-2">
                <label for="pitcure">Picture</label>
                <input type="file" class="form-control-file ps-1" id="picture">
            </div>

            <div class="form-group mt-1">
                <label for="caption">Picture Caption</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Picture Caption">
            </div>
            <div class="form-group mb-2 mt-2">
                <label for="content">Content</label>
                <Editor :api-key="editorkey" :init="{
                    menubar: false,
                    toolbar: 'bold italic'
                }" />
            </div>

            <div class="form-group mt-1">
                <label for="iposition">Detail Page</label>
                <select class="form-control" id="iposition">
                    <option>Enable</option>
                    <option>Disable</option>
                </select>
            </div>
            <div class="form-group mt-1">
                <label for="keyword">Meta Keywords</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Meta Keywords">
            </div>
            <div class="form-group mt-1">
                <label for="Meta Description">Meta Description</label>
                <textarea class="form-control" row="3"></textarea>
            </div>

            <div class="row p-2 mt-1">
                <button type="button" class="btn-primary btn-lg btn-block">Save</button>
            </div>
        </form>
    </div>
</template>
<script>
/* eslint-disable */
import Editor from '@tinymce/tinymce-vue';
export default {
    name: "servicecom",
    props: ["obj"],
    components: {
        Editor

    }

}
</script>
<style scoped></style>