<template>
  <div class="container">

    <div v-if="!issignup" class="row justify-content-center">
      <div class="col-lg-5">

        <div class="card shadow-lg border-0 rounded-lg mt-5">
          <CAwait v-if="!asyncStatus_ready" />
          <div class="card-header">
            <h3 class="text-center font-weight-light my-4">Login</h3>
          </div>
          <div class="card-body">

            <CAlert v-if="message" title="Login" :detail="message" :type="issuccess" />
            <form>
              <div class="form-floating mb-3">
                <input class="form-control" id="username" type="text" placeholder="Username" v-model="username"
                  autocomplete />
                <label for="username">Username</label>
              </div>
              <div class="form-floating mb-3">
                <input class="form-control" id="inputPassword" v-model="password" type="password" autocomplete
                  @keyup.enter="login" placeholder="Password" />
                <label for="inputPassword">Password</label>
              </div>

              <div class="mt-4 mb-0">
                <div class="d-grid">
                  <a class="btn btn-primary" @click="login">Login</a>
                </div>
              </div>
              <div class="card-footer text-center py-3">
                <div class="small"><a href="#" @click="issignup = true">Need an account? Sign up!</a></div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
    <!-- Signup UI-->
    <div v-if="issignup" class="row justify-content-center">
      <div class="col-lg-7">
        <div class="card shadow-lg border-0 rounded-lg mt-5">
          <div class="card-header">

            <h3 class="text-center font-weight-light my-4">Create Account</h3>
          </div>
          <div class="card-body">
            <CAwait v-if="!asyncStatus_ready" />
            <CAlert v-if="message" title="Signup" :detail="message" :type="issuccess" />
            <form>
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="form-floating mb-3 mb-md-0 mb-3 mb-md-0">
                    <input class="form-control" id="username" type="text" v-model="username" @keyup.enter="checkuser"
                      placeholder="Enter username" autocomplete />
                    <label for="inputusername">Username</label>
                  </div>
                  <div v-if="errorusername" class="text-danger mt-1">
                    {{ errorusername }}
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-floating mb-3 mb-md-0">
                    <input class="form-control" id="inputPassword" v-model="password" autocomplete type="password"
                      placeholder="Create a password" />
                    <label for="inputPassword">Password</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mb-3 mb-md-0">
                    <input class="form-control" id="inputPasswordConfirm" type="password" v-model="confirmpassword"
                      placeholder="Confirm password" @keyup.enter="signup" autocomplete />
                    <label for="inputPasswordConfirm">Confirm Password</label>
                  </div>
                  <div v-if="errorpassword" class="text-danger mt-1">
                    {{ errorpassword }}
                  </div>
                </div>
              </div>
              <div class="mt-4 mb-0">
                <div class="d-grid"><a class="btn btn-primary btn-block" @click="signup">Create Account</a></div>
              </div>
            </form>
          </div>
          <div class="card-footer text-center py-3">
            <div class="small"><a href="/" @click="issignup = false">Have an account? Go to login</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CInternetConnection from "@/components/CInternetConnection.vue";
import CAwait from '@/components/CAwait.vue';
import CAlert from '@/components/CAlert.vue';
const axios = require("axios").default;

export default {
  name: "home",
  setup() {

  },
  components: {
    CInternetConnection,
    CAwait,
    CAlert


  },
  created() {
    if (this.token) {
      this.$router.push('/section')
    } else this.$router.push('/');

  },
  data() {
    return {
      username: "",
      errorusername: "",
      password: "",
      confirmpassword: "",
      errorpassword: "",
      issignup: false,
      message: "",
      issuccess: "false"

    };
  },

  computed: {

  },

  methods: {
    async signup() {
      this.checkuser();
      this.checkpassword();
      if (!this.errorpassword && !this.errorusername) {
        this.notasyncDatafetchedStatus();
        this.username = this.username.toLowerCase();
        const data = { username: this.username, password: this.password, confirmpassword: this.confirmpassword };
        await axios.post(process.env.VUE_APP_API_URL + "site/signup", JSON.stringify(data)).then((value) => {
          if (value.data.data) {
            this.issuccess = true;
            this.message = "Signup Successfully <a href='/'>Login Now</a>";
          } else {
            this.issuccess = false;
            this.message = value.data.error;
          }
        });

        this.asyncDatafetchedStatus();
      }

    },
    checkuser() {
      var result = this.validUsername(this.username)
      if (!result) {
        this.errorusername = "Invalid username";
      } else this.errorusername = "";
    },
    checkpassword() {
      if (this.validPassword(this.password) && this.password == this.confirmpassword) {
        this.errorpassword = "";
      } else this.errorpassword = "Password invalid or not match";
    },
    async login() {
      this.notasyncDatafetchedStatus();
      this.username = this.username.toLowerCase();
      const data = { username: this.username, password: this.password };
      await this.$store.dispatch("getprofile", data);
      this.asyncDatafetchedStatus();
      if (this.token) {
        this.$router.push('/section')
      } else {
        this.issuccess = false;
        this.message = "Incorrect username or password";
      }


    },
    isvaliddomain(domain_name) {
      var re = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;
      return re.test(domain_name);
    },
    viewall() {
      this.$router.push({ name: 'ListDomain' })
    },
    domainlist(type) {
      return this.products.filter((pro) => pro.type === type);
    },
    Catbyid(catid) {
      return this.categories.find((category) => category.id === catid);
    },
    getdomainprice(ckey) {
      var result = this.products.find((pro) => pro.classkey === ckey);
      this.domainprice = result.selling_price;
    },
    isdomainavailableforsale(dmc) {
      var result = this.products.find((pro) => pro.name === dmc);
      if (!result) {
        return false;
      }
      return true;
    },
    async getlistorder(token) {
      this.notasyncDatafetchedStatus();
      await this.$store.dispatch("amyorderlst", token);
      this.asyncDatafetchedStatus();

    }
  },
  watch: {

    search() {
      this.searchResult = null;
      this.notavaible = null;
      const e = this.$refs.btsearch;
      if (this.search.length > 0) {
        e.disabled = false;
      } else {
        e.disabled = true;
      }


    }
  },

  mounted() {
    this.asyncDatafetchedStatus();
  },
};
</script>

<style scoped>
.headerm {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 4px;
  height: 50px;
  max-height: 50px;
}

.domainlist {
  max-height: 25vh;
  overflow: scroll;
  margin-bottom: 8px;
}

.catbook {
  white-space: nowrap;
  margin: 5px;
  border-radius: 9px;
  padding: 10px;

  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: bold;
}

#searchd {
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 0px 4px 4px 0px;
  background-color: #5CC1B4;
  border-width: 0.1px;
  border-color: #d0f5f0;
  z-index: 30;
}

#searchd:active {
  background-color: #09ac97;
}

#searchd:hover {
  background-color: #09ac97;
}

#searchd:disabled {
  background-color: #5CC1B4;
}

#viewall {
  font-weight: bold;
  font-size: 1em;
  padding: 3px;
  color: #5CC1B4;


}



p {
  text-align: center;
}

#divbook {
  padding: 0px 10px 0px 0px;
}

.button {
  background-color: #999999;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #ffffff;
  text-decoration: none;
}

div.scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: transparent;
  font-weight: bold;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
  /* Remove scrollbar space */
  height: 0px;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.searchresult {
  border: 2px solid red;
  border-radius: 5px;
  padding: 8px;
  color: #57636C;
  margin-bottom: 8px;
}

.disablebtn {
  opacity: 0.7;
}

input:focus {
  box-shadow: none;
}
</style>
