<template>
    <CWaiting v-if="!asyncStatus_ready" />
    <div v-else>
        <CAlert v-if="result" title="Section" :detail="message" :type="issuccess" />

        <div class="row">
            <div class="col-3">
                <div class="form-group mt-1">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="obj1.title"
                        placeholder="Title">
                </div>
                <div class="form-group mt-1">
                    <label for="caption">Caption</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Picture Caption"
                        v-model="obj1.caption">
                </div>

                <div class="row mt-1">
                    <label for="pitcure">Image 1</label>
                    <div v-show="fbase64"><img :src="fbase64" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <div v-show="i1 && !fbase64"><img :src="i1" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <input type="file" class="form-control-file" id="picture" @change="ichange" accept="image/*" />
                </div>

            </div>
            <div class="col-3">
                <div class="form-group mt-1">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="obj2.title"
                        placeholder="Title">
                </div>
                <div class="form-group mt-1">
                    <label for="caption">Caption</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Picture Caption"
                        v-model="obj2.caption">
                </div>
                <div class="row mt-1">
                    <label for="pitcure">Image 2</label>
                    <div v-show="sbase64"><img :src="sbase64" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <div v-show="i2 && !sbase64"><img :src="i2" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <input type="file" class="form-control-file" id="picture" @change="i2change" accept="image/*" />
                </div>

            </div>
            <div class="col-3">
                <div class="form-group mt-1">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="obj3.title"
                        placeholder="Title">
                </div>
                <div class="form-group mt-1">
                    <label for="caption">Caption</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Picture Caption"
                        v-model="obj3.caption">
                </div>
                <div class="row mt-1">
                    <label for="pitcure">Image 3</label>
                    <div v-show="tbase64"><img :src="tbase64" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <div v-show="i3 && !tbase64"><img :src="i3" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <input type="file" class="form-control-file" id="picture" @change="i3change" accept="image/*" />
                </div>

            </div>
        </div>
        <div class="row p-2">
            <button type="button" class="btn-primary btn-lg btn-block" @click="savedata">Save</button>

        </div>
    </div>
</template>
<script>
/* eslint-disable */
import CAlert from '@/components/CAlert.vue';
import CWaiting from '@/components/CWaiting.vue';
export default {
    name: "standardcom",
    props: ["obj"],
    created() {
        if (this.obj) {

            this.setData(this.obj);
        }
    },
    watch: {
        obj() {
            this.setData(this.obj);
        }
    },
    data() {
        return {
            title: "",
            obj1: { title: "", caption: "", filename: "", filesize: 0 },
            obj2: { title: "", caption: "", filename: "", filesize: 0 },
            obj3: { title: "", caption: "", filename: "", filesize: 0 },
            issuccess: "",
            message: "",
            result: null,
            fbase64: null,
            sbase64: null,
            tbase64: null,
            i1: "",
            i2: "",
            i3: "",
            id: 0,

        };
    },

    components: {
        CAlert,
        CWaiting

    },
    mounted() {
        this.asyncDatafetchedStatus();
    },
    methods: {
        setData(obj) {
            this.id = obj.sec_id;
            const dt = JSON.parse(obj.sec_data);
            this.obj1 = dt['img1'];
            this.obj2 = dt['img2'];
            this.obj3 = dt['img3'];
            if (dt['img1'].filename) {
                this.i1 = process.env.VUE_APP_MEDIA_URL + dt['img1'].filename;
            }
            if (dt['img2'].filename) {
                this.i2 = process.env.VUE_APP_MEDIA_URL + dt['img2'].filename;
            }
            if (dt['img3'].filename) {
                this.i3 = process.env.VUE_APP_MEDIA_URL + dt['img3'].filename;

            }


        },
        async savedata() {
            this.notasyncDatafetchedStatus();
            const dt = JSON.stringify({
                img1: this.obj1,
                img2: this.obj2,
                img3: this.obj3
            });
            const postdata = {
                section_id: this.id,
                section_type: 'carousel',
                section_data: dt,
                sorder: 1,
                sname: "Carousel",
                language: 'en',
                fbase64: this.fbase64,
                sbase64: this.sbase64,
                tbase64: this.tbase64,
            }
            var result;
            if (this.id > 0) {
                result = await this.$store.dispatch("Editsection", postdata);
            } else result = await this.$store.dispatch("Addsection", postdata);
            if (result != false && result.sec_id > 0) {
                this.result = result;
                this.issuccess = true;
                this.message = "Success";
            } else {
                this.issuccess = false;
                this.message = "Failed";
            }
            this.asyncDatafetchedStatus();

        },
        ichange(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            const reader = new FileReader();
            reader.onload = (event) => {
                this.fbase64 = event.target.result;
                this.obj1.filename = img[0].name;
                this.obj1.filesize = img[0].size;
            }
            reader.readAsDataURL(img[0]);

        },
        i2change(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            const reader = new FileReader();
            reader.onload = (event) => {
                this.sbase64 = event.target.result;
                this.obj2.filename = img[0].name;
                this.obj2.filesize = img[0].size;
            }
            reader.readAsDataURL(img[0]);

        },
        i3change(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            const reader = new FileReader();
            reader.onload = (event) => {
                this.tbase64 = event.target.result;
                this.obj3.filename = img[0].name;
                this.obj3.filesize = img[0].size;
            }
            reader.readAsDataURL(img[0]);

        }
    },




}
</script>
<style scoped></style>