<template>
    <CWaiting v-if="!asyncStatus_ready" />
    <div>
        <CAlert v-if="result" title="Section" :detail="message" :type="issuccess" />
        <form class="form">
            <div class="form-group mt-1">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" v-model="title" placeholder="Title">
            </div>
            <div class="form-group mt-1" v-if="id > 0">
                <label for="Order">Section Order</label>
                <input type="number" class="form-control" v-model="order" placeholder="Title">
            </div>
            <div class="form-group mt-1">
                <label for="content">Content</label>
                <Editor :api-key="editorkey" v-model="desc" :init="{
                    menubar: false,
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
                }" />
            </div>

            <div class="row mt-1">
                <label for="pitcure">Picture</label>
                <div v-show="base64"><img :src="base64" width="100" class="img-thumbnail"
                        style="margin: 0px 0px 8px 0px;" />
                </div>
                <div v-show="imgurl && !base64"><img :src="imgurl" width="100" class="img-thumbnail"
                        style="margin: 0px 0px 8px 0px;" />
                </div>
                <input type="file" class="form-control-file" id="picture" @change="ichange" accept="image/*" />
            </div>
            <div class="form-group mt-1">
                <label for="iposition">Picture Position</label>
                <select class="form-control" id="iposition" v-model="postion">
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                    <option value="top">Top</option>
                    <option value="down">Buttom</option>
                </select>
            </div>
            <div class="form-group mt-1">
                <label for="caption">Picture Caption</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Picture Caption"
                    v-model="caption">
            </div>
            <div class="row p-2">
                <button type="button" class="btn-primary btn-lg btn-block" @click="savedata">Save</button>

            </div>
        </form>
    </div>
</template>
<script>
/* eslint-disable */
import Editor from '@tinymce/tinymce-vue';
import CAlert from '@/components/CAlert.vue';
import CWaiting from '@/components/CWaiting.vue';
export default {
    props: ["obj"],
    name: "standardcom",
    data() {
        return {
            title: "",
            caption: "",
            postion: "top",
            desc: "",
            base64: null,
            file: null,
            issuccess: "",
            imgurl: null,
            result: null,
            id: 0,
            order: 0,
            message: "",

        };
    },
    mounted() {
        this.asyncDatafetchedStatus();
        if (this.obj) {
            this.setData(this.obj);
        }


    },
    watch: {
        obj() {

            this.setData(this.obj);
        }

    },
    components: {
        Editor,
        CAlert,
        CWaiting,

    },
    methods: {
        setData(obj) {
            this.order = obj.sec_order;
            const dt = JSON.parse(obj.sec_data);
            this.title = obj.sec_name;
            this.id = obj.sec_id;
            this.desc = dt.content;
            this.postion = dt.picture_position;
            this.caption = dt.picture_caption;
            if (dt.filename != "") {
                this.imgurl = process.env.VUE_APP_MEDIA_URL + dt.filename
            } else this.imgurl = "";

        },
        async savedata() {
            this.notasyncDatafetchedStatus();
            var filename = "";
            var filesize = 0;
            if (this.base64 != null) {
                filename = this.file.name;
                filesize = this.file.size;
            } else if (this.id > 0 && !this.base64) {
                const dt = JSON.parse(this.obj.sec_data);
                filename = dt.filename;
                filesize = dt.filesize;
            }

            const dt = JSON.stringify({
                title: this.title,
                content: this.desc,
                filesize: filesize,
                filename: filename,
                picture_position: this.postion,
                picture_caption: this.caption
            });
            const postdata = {
                section_id: this.id,
                section_type: 'standard',
                section_data: dt,
                sorder: this.order,
                base64: this.base64,
                sname: this.title,
                language: 'en'
            }
            var rs;
            if (this.id > 0) {
                rs = await this.$store.dispatch("Editsection", postdata);
            } else rs = await this.$store.dispatch("Addsection", postdata);
            if (rs != false && rs.sec_id > 0) {
                this.result = rs;
                this.issuccess = true;
                this.message = "Success";
            } else {
                this.issuccess = false;
                this.message = "Failed";
            }
            this.asyncDatafetchedStatus();

        },
        ichange(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            this.file = img[0];
            this.createBase64Image(img[0]);

        },
        createBase64Image(FileObject) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.base64 = event.target.result;
            }
            reader.readAsDataURL(FileObject);
        }

    },




}
</script>
<style scoped></style>