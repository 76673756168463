<template>
    <CWaiting v-if="!asyncStatus_ready" />
    <div v-else>
        <CAlert v-if="result" title="Section" :detail="message" :type="issuccess" />

        <form>
            <div class="form-group mt-1">
                <label for="title">Title</label>
                <input type="text" v-model="title" class="form-control" id="exampleFormControlInput1" placeholder="Title">
            </div>
            <div class="form-group mt-1" v-if="id > 0">
                <label for="Order">Section Order</label>
                <input type="number" class="form-control" v-model="order" placeholder="Title">
            </div>
            <div class="row mt-1">
                <div class="form-group">
                    <label for="content">Content</label>
                    <Editor :api-key="editorkey" v-model="desc" :init="{
                        menubar: false,
                        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
                    }" />
                </div>
            </div>
            <div class="row mt-2 p-2">
                <button type="button" class="btn btn-primary btn-lg btn-block" @click="savecard">Save</button>
            </div>
        </form>
    </div>
</template>
<script>
/* eslint-disable */
import Editor from '@tinymce/tinymce-vue';
import CAlert from '@/components/CAlert.vue';
import CWaiting from '@/components/CWaiting.vue';
export default {
    name: "jumbocom",
    props: ["obj"],
    data() {
        return {
            title: "",
            desc: "",
            issuccess: "",
            result: null,
            id: 0,
            order: 0,
            message: "",

        };
    },
    created() {
        if (this.obj) {
            this.setData(this.obj);
        }

    },
    mounted() {
        this.asyncDatafetchedStatus();
    },
    watch: {
        obj() {
            this.setData(this.obj);
        }
    },
    methods: {
        setData(obj) {
            this.order = obj.sec_order;
            const dt = JSON.parse(obj.sec_data);
            this.title = dt.title;
            this.id = obj.sec_id;
            this.desc = dt.content;

        },
        async savecard() {
            this.notasyncDatafetchedStatus();
            const dt = JSON.stringify({
                title: this.title,
                content: this.desc,

            });
            const postdata = {
                section_id: this.id,
                section_type: 'jumbotron',
                section_data: dt,
                sorder: this.order,
                sname: this.title,
                language: 'en'
            }
            var result;
            if (this.id > 0) {
                result = await this.$store.dispatch("Editsection", postdata);
            } else result = await this.$store.dispatch("Addsection", postdata);
            if (result != false && result.sec_id > 0) {
                this.result = result;
                this.issuccess = true;
                this.message = "Success";
            } else {
                this.issuccess = false;
                this.message = "Failed";
            }
            this.asyncDatafetchedStatus();

        }
    },
    components: {
        Editor,
        CAlert,
        CWaiting,

    }

}
</script>
<style scoped></style>