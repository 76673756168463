<template>
    <div>
        <div v-if="token" class="topmenu">
            <span style="font-weight: bold;" v-if="profile">Welcome {{ profile.username.toUpperCase() }}</span>&nbsp;<button
                class="btn btn-dark btn-sm" @click="toogleMenu"><i class="fa-solid fa-bars"></i></button>
        </div>
        <div class="toggletopmenu" ref="mytg">
            <ul class="nav flex-column nav-pills" @click="hide">
                <li class=" nav-item">
                    <router-link to="/section" v-bind:class="$route.path === '/section' ? 'nav-link active' : 'nav-link'"><i
                            class="fa-solid fa-window-maximize"></i> Section</router-link>
                </li>
                <!--<li class="nav-item">
                    <router-link to="/media" v-bind:class="$route.path === '/media' ? 'nav-link active' : 'nav-link'"><i
                            class="fa fa-file"></i> Media</router-link>
                </li>-->
                <li class="nav-item">
                    <router-link v-bind:class="$route.path === '/setting' ? 'nav-link active' : 'nav-link'" to="/setting"><i
                            class="fa fa-gear"></i> Setting</router-link>
                </li>
                <hr style="color: white;" />
                <li class="nav-item">
                    <router-link v-bind:class="$route.path === '/profile' ? 'nav-link active' : 'nav-link'" to="/profile"><i
                            class="fa fa-key fa-solid"></i> Password</router-link>
                </li>
                <li class="nav-item">
                    <button class="btn btn-dark" @click="logout"><i class="fa fa-sign-out"></i> Sign Out</button>
                </li>
            </ul>
        </div>

        <!--<div v-bind:class="show ? 'leftmenu' : 'leftmenu hide'">

            <div style="position:absolute; right:0; top:0;" @click="show = !show"><button class="btn bg-dark btn-sm">

                    <i
                        v-bind:class="show ? 'fa-solid fa-chevron-left fa-lg text-light' : 'fa-solid fa-chevron-right fa-lg text-light'"></i></button>


            </div>
            <div v-show="show" style="width: 100%;"><img :src="logo" style="height: 80px;" />
            </div>
            <ul v-show="show" class="nav flex-column nav-pills">

                <li class=" nav-item">
                    <router-link to="/" v-bind:class="$route.path === '/' ? 'nav-link active' : 'nav-link'"><i
                            class="fa-solid fa-window-maximize"></i> Section</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/media" v-bind:class="$route.path === '/media' ? 'nav-link active' : 'nav-link'"><i
                            class="fa fa-file"></i> Media</router-link>
                </li>
                <li class="nav-item">
                    <router-link v-bind:class="$route.path === '/setting' ? 'nav-link active' : 'nav-link'" to="/setting"><i
                            class="fa fa-gear"></i> Setting</router-link>
                </li>

            </ul>
            <div v-show="show" style="color:white;width:100%;display: block;font-weight: bold;">
                <hr style="height: 2px;" />

                <div style="display: flex; width: 100%; align-items: center; padding:8px;">
                    <div style="flex:2;display: inline-flex; align-items: center; font-size: small;">
                        <div style="display: block; text-align: left;">
                            <div v-if="profile && profile.name">{{ profile.name }}</div>
                            <div>Admin</div>
                        </div>
                    </div>


                    <router-link to="/profile" style="color: white;">
                        <div><i class="fa fa-gear fa-lg"></i></div>
                    </router-link>
                </div>
                <div class="logout"><i class="fa-solid fa-arrow-right-to-bracket"></i>
                    Log Off</div>
            </div>
        </div> -->
        <breadcrumb :links="breakcrumbs" v-show="token" />
        <div v-if="token && domain"
            style="position:fixed;top:55px;right:0; text-align: right;font-weight: bold;font-size: 20px;margin:8px;">
            <a v-if="domain" :href="dom + domain.domain_name" target="_blank"><i class="fa fa-globe"></i> Site</a>
        </div>

        <div class="container bm">
            <RouterView />
        </div>
    </div>
</template>
<script>
import logo from '@/assets/website.gif'
import cwh from '@/assets/cwh.png';
import breadcrumb from '@/components/CBreadcrumb.vue';
export default {
    data() {
        return {
            show: true,
            logo: logo,
            cwh: cwh,
            dom: "http://",
            screenWidth: window.innerWidth,

        }

    },
    components: {
        breadcrumb,
    },
    methods: {

        logout() {
            this.clearlocalstore();
            this.$store.state.token = null;
            this.$router.push('/');
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
        },
        toogleMenu() {
            const e = this.$refs.mytg;
            e.classList.toggle("tactive");
            e.classList.remove("toggletopmenu");

        },
        hide() {
            const e = this.$refs.mytg;
            e.classList.toggle("toggletopmenu");
            e.classList.remove("tactive");
        }
    },
    watch: {


    },
    created() {
        if (!this.token) {
            this.$router.push('/');
        }

    },
    mounted() {
        window.addEventListener("resize", this.handleResize);



    },
}
</script>
<style scoped>
.leftmenu {
    display: block;
    position: fixed;
    padding-top: 50px;
    width: 120px;
    height: 100dvw;
    background-color: #131313;
    overflow: hidden;
    z-index: 2;

}

.topmenu {
    position: absolute;
    top: 0;
    background-color: black;
    color: white;
    width: 100%;
    min-width: 300px !important;
    height: 50px;
    z-index: 1;
    text-align: right;
    padding: 12px;
}

.toggletopmenu {
    display: none;
    width: 300px !important;
}

.tactive {
    display: block;
    position: absolute;
    background-color: #131313;
    top: 3rem;
    right: 0;
    justify-content: left;
    width: auto;
    z-index: 3;

}

/* For mobile phones: */
[class*="col-"] {
    width: 100%;
}

@media screen and (min-width:200px) {

    /**For mobile */
    .leftmenu {
        display: none;
    }

    .bm {
        min-width: 300px;
        overflow-x: scroll;

    }

}

@media only screen and (min-width: 600px) {

    /* For tablets: */
    .leftmenu {
        display: block;
        position: relative;


    }

    .bm {
        position: relative;
        z-index: -1;

    }

}

@media only screen and (min-width: 768px) {

    /* For desktop: */
    .leftmenu {
        display: block;
        position: relative;
    }


}

button:hover,
button:active,
button::after {
    box-shadow: none;
}

ul li a {
    color: white;
    font-weight: bold;
}


ul li .nav-link:hover:not(.active) {
    color: white;
}

ul li:hover:not(.active) {

    background-color: #5c636a;

}

.hide {

    width: 30px;
    height: 30px;

}

.bm {
    background-color: white;
    width: 100%;
    height: 100dvh;
    margin-top: 0px;
    padding: 12px;
    flex: 1%;
    z-index: 1;


}

.logout {
    width: 150px;
    padding: 8px;
}

.logout:hover {
    transition: width 1s;
    width: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-width: 2px;
    background-color: #5c636a;
    cursor: pointer;

}

img {
    clip-path: circle(50%);
    margin: 4px;
}
</style>