<template>
    <div class="container-fluid"
        style="margin-top: 50px;padding-top: 4px;padding-bottom: 0px;text-align: justify;font-weight: bold;">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li v-for="l, index in links" :key="l"
                    v-bind:class="$route.path === l.path ? 'breadcrumb-item active' : 'breadcrumb-item'">
                    <router-link v-if="links.length - 1 > index" :to="l.path">{{ l.name
                    }}</router-link>
                    <span v-if="links.length - 1 == index">{{ l.name }}</span>
                </li>
            </ol>
        </nav>
    </div>
</template>
<script>

export default {
    props: ['links']
}
</script>