<template>
    <div>
        <CWaiting v-if="!asyncStatus_ready" />
        <div v-else>
            <CAlert v-if="result" title="Web Setting" :detail="result" :type="issuccess" />
            <form>
                <div class="form-group mt-1">
                    <label for="title">Web Title</label>
                    <input type="text" class="form-control" v-model="title" placeholder="Title">
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="pitcure">Logo (50 px * 50px)</label>
                    <div v-show="displayimg"><img :src="displayimg" width="100" class="img-thumbnail"
                            style="margin: 0px 0px 8px 0px;" />
                    </div>
                    <input type="file" class="form-control-file ps-1" id="logo" @change="ichange">
                </div>
                <div class="form-group mt-1">
                    <label for="navmenu">Nav Menu Theme</label>
                    <select class="form-control" v-model="menutype">
                        <option value="primary">Primary</option>
                        <option value="dark">Dark</option>
                        <option value="info">Info</option>
                        <option value="danger">Red</option>
                    </select>
                </div>
                <div class="row mt-2 p-2">
                    <button type="button" class="btn btn-primary btn-lg btn-block" @click="setting">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import CInternetConnection from "@/components/CInternetConnection.vue";
import CWaiting from "@/components/CWaiting.vue";
import CAlert from '@/components/CAlert.vue';
export default {
    name: "WSetting",
    components: {

        CInternetConnection,
        CWaiting,
        CAlert,

    },
    created() {
        this.asyncDatafetchedStatus();
    },
    data() {
        return {
            title: null,
            menutype: "primary",
            displayimg: null,
            base64: null,
            filename: "",
            filesize: 0,
            issuccess: "",
            result: null,

        }
    },
    methods: {
        async setting() {

            const postdata = {
                title: this.title,
                menutype: this.menutype,
                base64: this.base64,
                filename: this.filename,
                filesize: this.filesize
            }
            this.notasyncDatafetchedStatus();
            var result = await this.$store.dispatch("domainSetting", postdata);
            this.asyncDatafetchedStatus();
            if (result != false && result.web_id > 0) {
                this.result = "Save Successfully";
                this.issuccess = true
            } else {
                this.issuccess = false;
                this.result = "Save Failed";
            }

        },
        ichange(e) {
            const img = e.target.files || e.dataTransfer.files;
            if (!img.length)
                return;
            const reader = new FileReader();
            reader.onload = (event) => {

                this.base64 = event.target.result;
                this.displayimg = this.base64;
                this.filename = img[0]['name'];
                this.filesize = img[0].size;

            }
            reader.readAsDataURL(img[0]);
        }

    },
    watch: {

    },
    mounted() {
        var bread = [{ name: 'Home', path: '/' }, { name: 'Web Setting', path: '/setting' }];
        this.$store.state.breakcrumbs = bread;
        this.title = this.websetting.title;
        this.menutype = this.websetting.menu_type;
        this.displayimg = process.env.VUE_APP_MEDIA_URL + this.websetting.logo;
    },

}
</script>
<style scoped>
input:focus {
    box-shadow: none;
}

button:disabled {
    background-color: #5CC1B4;
    opacity: 0.7;
}
</style>

