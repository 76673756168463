<template>
    <CWaiting v-if="!asyncStatus_ready" />
    <div v-else>

        <div style="display: flex;">
            <div v-show="sections && sections.length < 6"><button class="btn btn-secondary btn-sm ms-2"
                    style="border-radius: 50%;" @click="add"><i class="fa fa-plus fa-lg"></i></button>
            </div>
        </div>


        <div class="container mt-3">
            <table class="table table-dark">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Type</th>
                        <th>-</th>
                    </tr>
                </thead>
                <tbody v-for="section, index in sections " :key="section">

                    <tr>
                        <td>{{ section.sec_name }}<br />Order {{ section.sec_order }}</td>
                        <td>{{ section.sec_types }} &nbsp;<span class="btn btn-primary btn-sm"
                                @click="edit(section, index)"><i class="fa fa-edit text-light"></i></span>
                        </td>
                        <td><span class="btn btn-danger btn-sm" @click="rm(section, index)"><i
                                    class="fa fa-x text-light"></i></span>
                        </td>
                    </tr>

                </tbody>
            </table>
            <ul class="pagination">
                <li class="page-item" @click="prev" v-if="totalpage > 3"><a class="page-link">Previous</a></li>
                <div v-for="p in totalpage " :key="p">
                    <li v-bind:class="p == page ? 'page-item active' : 'page-item'" v-if="p >= pagestart && p <= pageend"
                        @click="page = p">
                        <a class="page-link" href="#">{{
                            p }}</a>
                    </li>
                </div>

                <li class="page-item" @click="next" v-if="totalpage > 3"><a class="page-link" href="#">Next</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import router from '@/router';
import CWaiting from "@/components/CWaiting.vue";
const axios = require("axios").default;
export default {
    name: "Section",
    components: {
        CWaiting
    },
    data() {
        return {
            id: 0,
            page: 1,
            index: 0,
            pagestart: 1,
            pageend: 3,

        };
    },
    created() {
        var bread = [{ name: 'Home', path: '/' }, { name: 'Section', path: '/section' }];
        this.$store.state.breakcrumbs = bread;

        this.getlist();

    },
    watch: {

        page() {
            this.getlist();

            if (this.page == 1) {
                this.pagestart = 1;
                this.pageend = 3;
            }
            else if (this.page == this.totalpage) {
                this.pageend = this.totalpage;

            } else {
                this.pagestart = this.page - 1;
                this.pageend = this.page + 1;

            }


        }
    },
    methods: {
        async getlist() {
            this.notasyncDatafetchedStatus();
            await this.$store.dispatch("Getsections", this.page);
            this.asyncDatafetchedStatus();

        },
        prev() {
            if (this.page > 1) {
                this.page = this.page - 1
            }


        },
        next() {
            if (this.page < this.totalpage) {
                this.page = this.page + 1
            }

        },
        edit(data, index) {
            router.push('/editsection/' + data.sec_id);
        },
        async rm(data, index) {
            this.notasyncDatafetchedStatus();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            await axios.post(process.env.VUE_APP_API_URL + "section/remove/" + data.sec_id).then((value) => {
                var result = value.data;
                if (result.message.toLowerCase().includes('delete')) {
                    this.sections.splice(this.sections.indexOf(data), 1);
                    
                }

            });
            this.asyncDatafetchedStatus();

        },
        add() {
            router.push('/editsection/' + this.id);
        }

    }
}
</script>
<style scoped></style>