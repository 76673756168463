<template>
  <div class="waiting">
    <div class="spinner-border text-success" style="width: 50px; height: 50px"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.waiting {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;


}
</style>