<template>
    <div v-if="!connected">
        <CInternetConnection />
    </div>
    <div v-else>
        <CWaiting v-if="!asyncStatus_ready" />
        <div v-else>
            <!--  <div style="padding-top:8px;">
                <input type="text" v-model="username" class="form-control" required placeholder="Email Address">
                <div v-show="mailerrmsg" style="margin-left:5px;margin-top:8px;font-size: 14px;" class="text-danger">{{
                    mailerrmsg
                }}
                </div>
            </div>-->
            <CAlert v-if="errcnt" title="Password" :detail="errmsg" :type="issuccess" />
            <CAlert v-if="successmessage" title="Password" :detail="successmessage" :type="issuccess" />
            <div class="mb-3">
                <label for="currentPassword" class="form-label">Current password</label>
                <input type="password" v-model="cpassword" class="form-control" id="currentPassword" name="currentPassword">
            </div>
            <div class="mb-3">
                <label for="newPassword" class="form-label">New password</label>
                <input type="password" v-model="newpassword" class="form-control" id="newPassword" name="newPassword">
            </div>
            <div class="mb-3">
                <label for="confirmPassword" class="form-label">Confirm password</label>
                <input type="password" v-model="confirmPassword" class="form-control" id="confirmPassword"
                    name="confirmPassword">
            </div>
            <button @click="update" class="btn btn-primary">Change password</button>


        </div>
    </div>
</template>

<script>
/* eslint-disable */
import CInternetConnection from "@/components/CInternetConnection.vue";
import CWaiting from "@/components/CWaiting.vue";
import simage from "@/components/simage.vue";
import axios from 'axios';
import CAlert from '@/components/CAlert.vue';
export default {
    name: "Setting",
    components: {

        CInternetConnection,
        CWaiting,
        simage,
        CAlert,
    },
    created() {
        this.username = this.profile.username;
        this.password = this.profile.password;
        this.asyncDatafetchedStatus();
    },
    data() {
        return {
            data: null,
            cpassword: "",
            newpassword: "",
            confirmPassword: "",
            errmsg: "",
            issuccess: false,
            isdisable: true,
            successmessage: "",
            errcnt: 0,

        }
    },
    methods: {
        async update() {
            this.errcnt = 0;
            this.successmessage = "";
            if (!navigator.onLine) {
                this.handleInternet();
            } else {
                if (this.newpassword != this.confirmPassword) {
                    this.errcnt++;
                    this.errmsg = "Incorect password";
                }
                if (this.newpassword.length < 8) {
                    this.errmsg = " required 8 digits minimum";
                    this.errcnt++;
                }
                this.notasyncDatafetchedStatus();
                if (this.errcnt == 0) {
                    this.errmsg = "";
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                    const data = { curpass: this.cpassword, newpass: this.newpassword, cnewpass: this.confirmPassword };
                    await axios.post(process.env.VUE_APP_API_URL + "site/update_password", JSON.stringify(data)).then((response) => {
                        console.log(response.data);
                        if (!response.data.error) {
                            this.$store.state.profile = response.data.data;
                            this.successmessage = response.data.message;
                            this.issuccess = true;
                        } else {
                            this.errmsg = response.data.error;
                            this.errcnt++;
                        }
                    })
                }
                this.asyncDatafetchedStatus();
            }
        },

    },
    watch: {

    },
    mounted() {
        var bread = [{ name: 'Home', path: '/' }, { name: 'Password Setting', path: '/profile' }];
        this.$store.state.breakcrumbs = bread;


    },

}
</script>
<style scoped>
input:focus {
    box-shadow: none;
}

button:disabled {
    background-color: #5CC1B4;
    opacity: 0.7;
}
</style>

