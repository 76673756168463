
<template>
  <div>{{ msg }}</div>
</template>

<script>
/* eslint-disable */

export default {
  name: "message_view",
  props: ["msg"],
  components: {

  },
};
</script>

<style></style>