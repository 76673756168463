<template>
    <div v-show="type == 0" class="alert alert-danger alert-dismissible fade show">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>{{ title }}!</strong> <span v-html="detail"></span>
    </div>
    <div v-show="type == 1" class="alert alert-success alert-dismissible fade show">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>{{ title }}!</strong>
        <span v-html="detail"></span>
    </div>
</template>
  
<script>
export default {
    props: ['title', 'detail', 'type']
};
</script>
  
<style scoped></style>