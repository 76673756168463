import { createStore, } from "vuex";
//import sourceData from '@/data.json';
import Languages from "@/assets/lang.json";
const axios = require("axios").default;
export default createStore({

    state: {
        token: null,
        profile: null,
        lang: Languages.items.find((la) => la.name === 'kh'),
        presult: null,
        apiurl: process.env.VUE_APP_API_URL,
        globalsetting: null,
        websetting: {
            "web_id": 0,
            "user_id": 0,
            "title": "",
            "menu_type": "primary",
            "logo": ""
        },
        domain: null,
        medias: [],
        totalpage: 1,
        currentpage: 1,
        sections: [],
        breakcrumbs: []


    },
    getters: {


    },
    actions: {

        async getprofile(context, data) {

            try {

                const headers = {
                    "Authorization": "",
                    "Content-Type": "application/json",
                };
                // const data = { username: 'kanha', password: 'kanha@123' };
                if (data.lang) {
                    context.commit('getlang', data.lang);

                } else {
                    context.commit('getlang', null);
                }
                await axios.post(process.env.VUE_APP_API_URL + "site/login", JSON.stringify(data, headers)).then((value) => {
                    if (value.data.status != false) {
                        context.commit('pullUserbyId', value.data.data);
                        context.commit('setWebSetting', value.data.websetting);
                        context.commit('setDomain', value.data.domain);
                    }

                });


            } catch (error) {
                console.log(error)
            }


        },
        async Addsection(context, dt) {
            var result = false;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.post(process.env.VUE_APP_API_URL + "section/create", JSON.stringify(dt)).then((value) => {
                result = value.data.data;
                this.dispatch('Getsections', this.state.currentpage);
            });
            return result;
        },
        async Addmedia(context, dt) {
            var result = false;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.post(process.env.VUE_APP_API_URL + "media/create", JSON.stringify(dt)).then((value) => {
                result = value.data.data;
            });
            return result;
        },
        async domainSetting(context, dt) {
            var result = false;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.post(process.env.VUE_APP_API_URL + "domain/setting", JSON.stringify(dt)).then((value) => {
                result = value.data.data;
                context.commit('setWebSetting', value.data.data);
            });
            return result;
        },
        async Getmedias(context, page) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.get(process.env.VUE_APP_API_URL + "media/list/" + page).then((value) => {
                const result = value.data;
                context.commit('setMedias', result);
            })
        },
        async Editmedia(context, data) {
            var result = false;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.post(process.env.VUE_APP_API_URL + "media/edit/" + data.id, JSON.stringify(data)).then((value) => {
                result = value.data.data;

            });
            return result;

        },
        async Getsections(context, page) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.get(process.env.VUE_APP_API_URL + "section/list/" + page).then((value) => {
                const result = value.data;
                context.commit('setSections', result);
            })
        },
        async Editsection(context, data) {
            var result = false;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
            await axios.post(process.env.VUE_APP_API_URL + "section/update/", JSON.stringify(data)).then((value) => {
                result = value.data.data;
                this.dispatch('Getsections', this.state.currentpage);

            });
            return result;
        },



    },
    mutations: {
        //do action

        pullUserbyId(state, suser) {
            state.profile = suser;
            state.token = suser.login_code;
            localStorage.token = suser.login_code;
            localStorage.user = JSON.stringify(suser);

        },
        //=================
        getlang(state, language) {
            const dtlang = Languages;
            if (language != null) {
                //state.lang = dtlang.items.find((la) => la.name === language);
                state.lang = dtlang.items.find((la) => language.toLowerCase().includes(la.name.toLowerCase()));
            }
        },
        globalSetting(state, data) {
            state.globalsetting = data;
            localStorage.globalsetting = data;
        },

        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        setWebSetting(state, data) {
            state.websetting = data;
            localStorage.websetting = JSON.stringify(data);
        },
        setDomain(state, data) {
            state.domain = data;
            localStorage.domain = JSON.stringify(data);
        },
        setMedias(state, data) {
            state.medias = data.data;
            state.totalpage = data.tpage;
            state.currentpage = data.cpage;
        },
        setSections(state, data) {
            state.sections = data.data;
            state.totalpage = data.tpage;
            state.currentpage = data.cpage;
        }


    }

});