<template>
    <div v-if="!asyncStatus_ready">
        <CWaiting />
    </div>
    <div v-else class="bg">

        <input type="file" style="display:none;" accept="image/*" @change="uploadImage($event)" ref="finput">
        <div class="imgholder" @click="$refs.finput.click()">
            <div v-if="profile.image"> <img :src="url + profile.image" class="img-thumbnail"
                    style="width: 110px; height:85px" /></div>
            <div v-else> <i class="fa fa-user fa-2x" style="opacity: 0.5"></i>

            </div>
            <i style="position:absolute;right:-5px;bottom:2px;color:#5CC1B4;" class="fa fa-camera fa-lg"></i>
        </div>
    </div>

</template>
<script>
/* eslint-disable */
import axios from 'axios';
import CWaiting from '@/components/CWaiting.vue';
export default {
    name: "simage",
    components: {
        CWaiting
    },
    data() {
        return {
            url: process.env.VUE_APP_MEDIA_URL,
            abaimgcon: null
        }
    },
    created() {
        this.asyncDatafetchedStatus();
    },
    methods: {
        async uploadImage(event) {
            this.notasyncDatafetchedStatus();
            let data = new FormData();
            data.append('file', event.target.files[0]);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axios.post(process.env.VUE_APP_API_URL + "uploadimage", data, config).then((response) => {
                const user = response.data.user;
                if (user) {
                    this.$store.state.profile = user;

                }
            });

            this.asyncDatafetchedStatus();
        },
    }

}
</script>

<style>
.bg {
    height: 150px;
    width: 100%;
    background-color: rgba(223, 201, 244, 0.415);
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.imgholder {
    position: relative;
    height: 70px;
    width: 70px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>